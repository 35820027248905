<template>
    <aside class="w-[250px] h-screen bg-white p-4 flex flex-col justify-between">
        <div class="space-y-4 mt-5">
            <ul class="space-y-4">
                <li class="cursor-pointer text-lg text-gray-700 flex items-center text-left"><i class="fas fa-user mr-2"></i> Profile</li>
                <li class="cursor-pointer text-lg text-gray-700 flex items-center text-left"><i class="fas fa-briefcase mr-2"></i> Job Board</li>
                <li class="cursor-pointer text-lg text-blue-500 flex items-center text-left"><i class="fas fa-cog mr-2"></i> Settings</li>
            </ul>
        </div>
        <div class="flex-grow"></div>
        <div class="space-y-4 mt-100">
            <div class="text-center">
                <p class="text-blue-500 font-lato text-[16px] font-bold leading-6 tracking-[0.1px] text-center">Get Matched</p>
                <p class="text-[#525252] font-lato text-[10px] leading-6 tracking-[0.1px] text-center">Finishing the profile will give you better chances.</p>
                <!--div class="w-20 h-20 rounded-full border-4 border-blue-500 flex items-center justify-center mx-auto">
            <div>Status</div>
            <div>1 of 5</div>
          </!--div-->
                <div class="relative w-20 h-20 ml-5">
                    <!-- Background Circle -->
                    <svg class="absolute top-0 left-0" width="120" height="120">
                        <circle cx="40" cy="40" r="35" fill="none" stroke="#E0E4EC" stroke-width="8" />
                    </svg>

                    <!-- Foreground Circle (Progress) -->
                    <svg class="absolute top-0 left-0 transform -rotate-90" width="80" height="80">
                        <circle cx="40" cy="40" r="35" fill="none" stroke="#0056D2" stroke-width="8" stroke-dasharray="220" stroke-dashoffset="88" stroke-linecap="round" />
                    </svg>

                    <!-- Text Inside the Circle -->
                    <div class="absolute inset-0 flex flex-col items-center justify-center text-center text-gray-700">
                        <span class="text-sm font-medium">Status</span>
                        <span class="text-lg font-semibold">1 of 5</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-grow mt-100"></div>

        <div class="cursor-pointer space-y-14 text-gray-500 text-lg flex justify-start"><i class="fas fa-sign-out-alt"></i> Log out</div>
    </aside>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: true,
            default: 40, // Progress value (percentage)
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
/* Additional styling here */
</style>
