<template>
    <button
        @click="SwitchLocale"
        :title="`Switch to ${this.locale === 'English' ? 'English' : 'French'}`"
        class="rounded-md flex flex-row justify-between items-center backdrop-blur-3xl hover:shadow-[0_0_5px_0_rgba(0,0,0,30%)] fixed bg-transparent hover:bg-white hover:pr-4 right-3 top-20 overflow-hidden z-50 group"
    >
        <img loading="lazy" decoding="async" :src="require(`@/assets/Images/${flag}`)" :alt="locale + ' flag'" class="w-12 h-full group-hover:h-12" />
        <span class="w-fit ml-2 text-black font-medium text-base">
            {{ locale }}
        </span>
    </button>
</template>

<script>
export default {
    name: "SwitchLocale",
    data() {
        return {
            locale: "",
        };
    },
    computed: {
        flag() {
            return this.locale === "English" ? "united-kingdom_flag.svg" : "france_flag.svg";
        },
    },
    methods: {
        SwitchLocale() {
            // console.log("current locale", this.flag, this.locale);
            this.$i18n.locale = this.$i18n.locale === "en" ? "fr" : "en";
            this.locale = this.$i18n.locale === "en" ? "French" : "English";
        },
    },
    mounted() {
        this.locale = this.$i18n.locale === "en" ? "French" : "English";
    },
};
</script>

<style lang="scss" scoped>
button {
    width: 3rem;
    height: 3rem;
    transition: all 700ms ease-out;
    &:hover {
        width: 7rem;
        padding: 0 8px 0 0;
    }
}
</style>
