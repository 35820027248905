<template>
    <div class="px-[5%] py-[10%]">
        <div class="w-[50%] mx-[25%]">
            <h1 class="text-[#3361FF] font-[600] text-[14px] uppercase">SELEcting the ideal candidate</h1>
            <h1 class="text-[38px] font-[600] my-4">{{ title }} Online Test Sample Questions</h1>
            <router-link to="/register" class="text-[17px] font-[500]"
                >Create a free account today to access the full {{ title }} skills assessment test and more from our assessment library</router-link
            >
        </div>
        <div class="grid grid-cols-3 gap-8 my-[5%]">
            <div v-for="(assessment, index) in assessments" :key="index" class="bg-[#DCE8F6] rounded-md px-[6%] py-[6%] text-left">
                <font-awesome-icon :icon="['fas', 'align-left']" class="text-[#3361FF]" />
                <h1 class="font-[600] text-[20px] mt-[5%]">Question</h1>
                <h1 class="font-[600] text-[20px] text-[#3361FF] my-4">{{ assessment.name }}</h1>
                <p class="text-[16px]">
                    {{ assessment.sampleQst }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SampleQst",
    props: {
        assessments: Array,
        title: String,
    },
};
</script>
